import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const HeaderLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 3rem;
  z-index: 2;
  background-color: ${(props) => props.theme.header};

  @media (max-width: ${breakpoints.screenMD}) {
    padding: 1.5rem;
  }

  &.fixed {
    position: fixed;
  }

  .menu-group {
    display: flex;
    align-items: center;
  }

  p {
    padding-right: 1.25rem;

    @media (max-width: ${breakpoints.screenSM}) {
      display: none;
    }
  }

  .logo {
    @media (max-width: ${breakpoints.screenSM}) {
      width: 70px;
      height: 70px;
    }
  }

  .menu {
    @media (max-width: ${breakpoints.screenSM}) {
      width: 40px;
      height: 40px;
    }

    &:hover {
      .menu-line:nth-child(2) {
        transform: translateY(-4px);
      }

      .menu-line:nth-child(3) {
        transform: translateY(4px);
      }
    }

    &.open {
      .menu-line:nth-child(2) {
        transform: translate(-5px, 30px) rotate(-45deg);
      }

      .menu-line:nth-child(3) {
        transform: translate(30px, -15px) rotate(45deg);
      }

      .menu-line:nth-child(4) {
        display: none;
      }
    }
  }

  .menu-line {
    transform: translate(0, 0) rotate(0);
    will-change: transform;
    transition: transform 0.2s linear;
  }
`

const MenuLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.backgroundBlue};
  transform: translateY(-100%);
  will-change: transform;
  transition: transform 0.2s linear;
  z-index: 2;

  &.open {
    transform: translateY(0);

    .container {
      transform: translateY(0);
    }

    h1 {
      transform: translateY(0);
    }
  }

  .container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    transform-origin: 50% 0;
    transform: translateY(-100%);
    will-change: transform;
    transition: transform 0.65s cubic-bezier(0.3, 1.36, 0.64, 1);
    padding: 18rem 16rem 10rem;

    @media (max-width: ${breakpoints.screenL}) {
      padding: 14rem 12rem 8rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      padding: 14rem 6rem 8rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      flex-direction: column;
      padding: 12rem 3rem 6rem;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
  }

  .link-item {
    overflow: hidden;
    margin-bottom: 1rem;

    &:nth-child(1) h1 {
      transition-delay: 0.4s;
    }

    &:nth-child(2) h1 {
      transition-delay: 0.45s;
    }

    &:nth-child(3) h1 {
      transition-delay: 0.5s;
    }

    &:nth-child(4) h1 {
      transition-delay: 0.55s;
    }
  }

  h1 {
    letter-spacing: 0.05em;
    transform: translateY(100%);
    will-change: transform;
    transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

    @media (max-width: ${breakpoints.screenSM}) {
      font-size: 2rem;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: auto;

    @media (max-width: ${breakpoints.screenSM}) {
      padding-bottom: 4rem;
    }
  }

  .link {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      right: 0;
      width: 100%;
      height: 1px;
      will-change: opacity;
      transition: opacity 0.4s ease;
      background-color: ${(props) => props.theme.textLight};
    }
  }

  .social a {
    margin-right: 1.5rem;
  }

  p {
    margin-bottom: 1.5rem;
  }
`

export { HeaderLayout, MenuLayout }
