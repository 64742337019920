import React from 'react'

import { colors } from '../../constants/theme'

const Surfs = ({ background = colors.white }) => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2379 48.0011V35.2007H12.1902V51.2002H42.6667V48.0011H36.5712H15.2379Z"
      fill={background}
    />
    <path
      d="M42.6663 60.8H12.1898V51.1997H9.14209V64.0001H45.7141V51.1997H42.6663V57.5999V60.8Z"
      fill={background}
    />
    <path d="M15.2379 9.60059H12.1902V12.8007H15.2379V9.60059Z" fill={background} />
    <path d="M12.1898 3.20044H9.14209V9.60063H12.1898V3.20044Z" fill={background} />
    <path
      d="M30.4766 6.40015H21.3334V9.60024H27.4289V22.3997H21.3334V9.60024H18.2857V12.8003H15.2379V15.9995H18.2857V22.3997H21.3334V25.5998H27.4289V22.3997H30.4766V15.9995V6.40015Z"
      fill={background}
    />
    <path
      d="M48.763 12.8003V9.60024H45.7152V22.3997H39.6198V9.60024H45.7152V6.40015H36.572V22.3997H39.6198V25.5998H45.7152V22.3997H48.763V19.1996H51.8107V15.9995H48.763V12.8003Z"
      fill={background}
    />
    <path d="M54.8585 12.8H51.8108V16.0001H54.8585V12.8Z" fill={background} />
    <path d="M57.9064 9.60059H54.8586V12.8007H60.9541V9.60059H57.9064Z" fill={background} />
    <path
      d="M60.9531 12.8V22.3994H57.9054V25.5995H60.9531V22.3994H64V12.8H60.9531Z"
      fill={background}
    />
    <path d="M57.9064 25.5996H54.8586V28.7997H57.9064V25.5996Z" fill={background} />
    <path d="M54.8585 28.8H51.8108V32.0002H54.8585V28.8Z" fill={background} />
    <path d="M51.8106 32.0002H48.7628V35.2003H51.8106V32.0002Z" fill={background} />
    <path d="M45.7145 38.4008V41.6009H48.7622V35.2007H45.7145V38.4008Z" fill={background} />
    <path d="M42.6676 44.8004V48.0005H45.7154V41.6003H42.6676V44.8004Z" fill={background} />
    <path d="M12.1898 25.5996H9.14209V35.1999H12.1898V28.7997V25.5996Z" fill={background} />
    <path d="M9.14345 22.3997H6.0957V25.5998H9.14345V22.3997Z" fill={background} />
    <path
      d="M6.09462 19.1993V22.3994H9.14236V19.1993H6.09462V12.8H3.04688V19.1993H6.09462Z"
      fill={background}
    />
    <path d="M0 6.40054V12.8007H3.04684V3.20044H0V6.40054Z" fill={background} />
    <path d="M6.09462 0H3.04688V3.2001H9.14236V0H6.09462Z" fill={background} />
    <path d="M36.5712 3.20044H30.4757V6.40054H36.5712V3.20044Z" fill={background} />
    <path d="M36.5712 22.3997H30.4757V25.5998H36.5712V22.3997Z" fill={background} />
  </svg>
)

export default Surfs
