import React from 'react'
import { Link } from 'gatsby'

import { colors } from '../constants/theme'

import FooterLayout from '../styles/FooterLayout'
import { ParagraphM } from '../styles/Typography'

import Form from './Form'
import Logo from './icons/logo'
import Facebook from './icons/facebook'
import Instagram from './icons/instagram'
import Linkedin from './icons/linkedin'

/**
 * The main footer of the website,
 * including the contact form.
 * @returns {StatelessComponent}
 */
const Footer = () => (
  <>
    <Form />
    <FooterLayout>
      <div className="top-left">
        <Link to="/">
          <Logo />
        </Link>
        <ParagraphM color={colors.white}>
          Mint & Square, <br /> kreativna agencija
        </ParagraphM>
      </div>
      <div className="top-right">
        <ParagraphM color={colors.white} className="title">
          Kontaktirajte nas
        </ParagraphM>
        <a href="mailto:info@mintandsquare.com">
          <ParagraphM color={colors.white} weight="300" className="link">
            info@mintandsquare.com
          </ParagraphM>
        </a>
        <a href="tel:0038651467360">
          <ParagraphM color={colors.white} weight="300" className="link">
            +386 (0) 51 467 360
          </ParagraphM>
        </a>
        <ParagraphM color={colors.white} weight="300">
          Tomšičeva 6, 1000 Ljubljana, Slovenija
        </ParagraphM>
      </div>
      <div className="bottom-left">
        <a href="https://www.instagram.com/mintandsquare" target="_blank">
          <Instagram />
        </a>
        <a href="https://www.facebook.com/mintandsquare" target="_blank">
          <Facebook />
        </a>
        <a href="https://www.linkedin.com/company/mintandsquare" target="_blank">
          <Linkedin />
        </a>
      </div>
      <div className="bottom-right">
        <ParagraphM color={colors.white} className="title">
          Raziskuj po strani
        </ParagraphM>
        <div className="links">
          <Link to="/">
            <ParagraphM color={colors.white} weight="300" className="link">
              Domača stran
            </ParagraphM>
          </Link>
          <Link to="/about">
            <ParagraphM color={colors.white} weight="300" className="link">
              O nas
            </ParagraphM>
          </Link>
          <Link to="/projects">
            <ParagraphM color={colors.white} weight="300" className="link">
              Projekti
            </ParagraphM>
          </Link>
          <Link to="/contact">
            <ParagraphM color={colors.white} weight="300" className="link">
              Kontakt
            </ParagraphM>
          </Link>
        </div>
      </div>
    </FooterLayout>
  </>
)

export default Footer
