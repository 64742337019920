import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const HeadlineL = styled.h1`
  font-size: 5rem; //90
  line-height: 1.4;
  font-family: ${(props) => (props.font ? props.font : props.theme.fontPrimary)};
  color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
  font-weight: ${(props) => (props.weight ? props.weight : '700')};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 3.35rem;
  }

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 2.5rem;
  }
`

const HeadlineM = styled.h1`
  font-size: 3.75rem; //60
  line-height: 1.4;
  font-family: ${(props) => (props.font ? props.font : props.theme.fontPrimary)};
  color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
  font-weight: ${(props) => (props.weight ? props.weight : '700')};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 3.35rem;
  }

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 2.5rem;
  }
`

const TitleL = styled.h2`
  font-size: 3.2rem; //50
  line-height: 1.2;
  font-family: ${(props) => (props.font ? props.font : props.theme.fontPrimary)};
  color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
  font-weight: ${(props) => (props.weight ? props.weight : '700')};

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 2.5rem;
  }
`

const TitleM = styled.h2`
  font-size: 2.5rem; //40
  line-height: 1.2;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
  font-weight: ${(props) => (props.weight ? props.weight : '700')};

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 1.9rem;
  }
`

const SubtitleL = styled.h3`
  font-size: 2.1rem; //34
  line-height: 1.2;
  letter-spacing: 0.05em;
  font-family: ${(props) => (props.font ? props.font : props.theme.fontPrimary)};
  color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
  font-weight: ${(props) => (props.weight ? props.weight : '700')};

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 1.35rem;
  }
`

const SubtitleM = styled.h3`
  font-size: 1.9rem; //30
  line-height: 1.6;
  letter-spacing: 0.05em;
  font-family: ${(props) => (props.font ? props.font : props.theme.fontPrimary)};
  color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
  font-weight: ${(props) => (props.weight ? props.weight : '700')};

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 1.35rem;
  }
`

const ParagraphL = styled.p`
  font-size: 1.4rem; //22
  line-height: 1.5;
  font-family: ${(props) => (props.font ? props.font : props.theme.fontPrimary)};
  color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
  font-weight: ${(props) => (props.weight ? props.weight : '700')};
`

const ParagraphM = styled.p`
  font-size: 1.25rem; //20
  line-height: 1.6;
  font-family: ${(props) => (props.font ? props.font : props.theme.fontPrimary)};
  color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
  font-weight: ${(props) => (props.weight ? props.weight : '700')};

  @media (max-width: ${breakpoints.screenMD}) {
    font-size: 1.1rem;
  }
`

const ParagraphS = styled.p`
  font-size: 1rem; //16
  line-height: 1.5;
  letter-spacing: 0.08em;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
  font-weight: ${(props) => (props.weight ? props.weight : '700')};
`

export {
  HeadlineL,
  HeadlineM,
  TitleL,
  TitleM,
  SubtitleL,
  SubtitleM,
  ParagraphL,
  ParagraphM,
  ParagraphS,
}
