import React from 'react'
import { Link } from 'gatsby'

import { colors } from '../constants/theme'

import { removeMenuClass } from '../helpers/ui'

import { MenuLayout } from '../styles/HeaderLayout'
import { HeadlineM, ParagraphM } from '../styles/Typography'

import Facebook from './icons/facebook'
import Instagram from './icons/instagram'
import Linkedin from './icons/linkedin'

/**
 * A menu overlay to be toggled on menu button click.
 * @returns {StatelessComponent}
 */
const Menu = () => (
  <MenuLayout id="menu">
    <div className="container">
      <div className="links">
        <Link to="/" className="link-item" onClick={removeMenuClass}>
          <HeadlineM color={colors.white}>Domov</HeadlineM>
        </Link>
        <Link to="/projects" className="link-item" onClick={removeMenuClass}>
          <HeadlineM color={colors.white}>Projekti</HeadlineM>
        </Link>
        <Link to="/about" className="link-item" onClick={removeMenuClass}>
          <HeadlineM color={colors.white}>O nas</HeadlineM>
        </Link>
        <Link to="/contact" className="link-item" onClick={removeMenuClass}>
          <HeadlineM color={colors.white}>Kontakt</HeadlineM>
        </Link>
      </div>
      <div className="contact">
        <a href="mailto:info@mintandsquare.com">
          <ParagraphM color={colors.white} weight="300" className="link">
            info@mintandsquare.com
          </ParagraphM>
        </a>
        <a href="tel:0038651467360">
          <ParagraphM color={colors.white} weight="300" className="link">
            +386 (0) 51 467 360
          </ParagraphM>
        </a>
        <ParagraphM color={colors.white} weight="300">
          Tomšičeva 6, 1000 Ljubljana, Slovenija
        </ParagraphM>
        <div className="social">
          <a href="https://www.instagram.com/mintandsquare" target="_blank">
            <Instagram />
          </a>
          <a href="https://www.facebook.com/mintandsquare" target="_blank">
            <Facebook />
          </a>
          <a href="https://www.linkedin.com/company/mintandsquare" target="_blank">
            <Linkedin />
          </a>
        </div>
      </div>
    </div>
  </MenuLayout>
)

export default Menu
