import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const leftMarquee = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`

const rightMarquee = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`

const bounce = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
`

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundPrimary};
  color: ${(props) => props.theme.textPrimary};
  height: 100%;
`

const Cursor = styled.div`
  position: fixed;
  top: -20px;
  left: -45px;
  pointer-events: none;
  width: 50px;
  height: 50px;
  border: 1px solid #d2d2d2;
  border-radius: 100%;
  z-index: 10;
  transition: transform 0.02s linear, opacity 0s;
  pointer-events: none;

  @media (max-width: ${breakpoints.screenMD}) {
    display: none;
  }
`

const CursorSmall = styled.div`
  position: fixed;
  top: -2px;
  left: -26px;
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.theme.textLight};
  z-index: 10;
  transition: transform 0.1s linear;
  pointer-events: none;
  mix-blend-mode: difference;

  @media (max-width: ${breakpoints.screenMD}) {
    display: none;
  }
`

const CursorView = styled.div`
  position: fixed;
  top: -3rem;
  left: -4rem;
  height: 75px;
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.textLight};
  z-index: 10;
  transition: transform 0.1s linear;
  pointer-events: none;

  @media (max-width: ${breakpoints.screenMD}) {
    display: none;
  }

  p {
    font-size: 0.8rem;
    line-height: 1.4;
  }
`

const Content = styled.div`
  max-width: 65%;
  margin: 0 auto 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.screenLG}) {
    max-width: 85%;
  }

  @media (max-width: ${breakpoints.screenMD}) {
    max-width: 100%;
  }
`

const FeaturedProjects = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  column-gap: 10rem;

  @media (max-width: ${breakpoints.screenL}) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  @media (max-width: ${breakpoints.screenMD}) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 5rem;
  }

  .project {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;

    @media (max-width: ${breakpoints.screenSM}) {
      padding-top: 2.5rem;
    }

    &:nth-child(odd) {
      padding-top: 6rem;

      @media (min-width: ${breakpoints.screenXXL}) {
        padding-top: 8rem;
      }

      @media (max-width: ${breakpoints.screenMD}) {
        padding-top: 4rem;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        padding-top: 2.5rem;
      }
    }

    &:nth-child(even) {
      @media (min-width: ${breakpoints.screenXXL}) {
        padding-top: 4rem;
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }

      .marquee {
        transform: translateX(-100%);
        transition: transform 20s linear;
      }

      h2 {
        color: ${(props) => props.theme.textHover};
      }

      .tag {
        color: ${(props) => props.theme.textHover};

        &:after {
          background-color: ${(props) => props.theme.textHover};
        }
      }
    }

    img {
      will-change: transform;
      transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    h2 {
      position: relative;
      will-change: color;
      transition: 0.4s ease;
      margin-bottom: 1rem;

      @media (max-width: ${breakpoints.screenMD}) {
        margin-bottom: 0.5rem;
      }
    }
  }

  .project-image {
    overflow: hidden;
    margin-bottom: 2rem;

    @media (max-width: ${breakpoints.screenMD}) {
      margin-bottom: 1rem;
    }
  }

  .marquee {
    white-space: nowrap;
    width: 100%;
    transform: translateX(0);
    transition: transform 6s cubic-bezier(0.08, 0.03, 0.22, 0.87);
  }

  .tag {
    position: relative;
    padding-right: 3rem;
    will-change: color;
    transition: 0.4s ease;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 1.2rem;
      width: 10px;
      height: 2px;
      background-color: ${(props) => props.theme.textPrimary};
    }

    &:last-child:after {
      display: none;
    }
  }
`

const ProjectsSquare = styled.div`
  position: relative;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.1);
    }

    .box {
      opacity: 1;
    }
  }

  img {
    will-change: transform;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    will-change: opacity;
    opacity: 0;
    transition: opacity 0.6s ease;
  }

  .text {
    text-align: center;
    z-index: 1;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.backgroundPurple};
    opacity: 0.5;
  }
`

const TeamSquare = styled.div`
  position: relative;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.1);

      @media (max-width: ${breakpoints.screenSM}) {
        transform: none;
      }
    }

    .box {
      opacity: 1;
    }
  }

  img {
    will-change: transform;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    will-change: opacity;
    opacity: 0;
    transition: opacity 0.6s ease;

    @media (max-width: ${breakpoints.screenSM}) {
      position: relative;
      height: unset;
      padding: 1rem 0;
      opacity: 1;

      h3,
      p {
        color: ${(props) => props.theme.textPrimary};
      }
    }
  }

  .text {
    text-align: center;
    z-index: 1;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.background};
    opacity: 0.5;

    @media (max-width: ${breakpoints.screenSM}) {
      display: none;
    }
  }
`

const Marquee = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: 100%;

  &:hover .marquee-left,
  &:hover .marquee-right {
    animation-play-state: paused;
  }

  .marquee-left {
    display: flex;
    animation: ${leftMarquee} 80s linear infinite;
  }

  .marquee-right {
    display: flex;
    animation: ${rightMarquee} 80s linear infinite;
  }

  .tag {
    position: relative;
    white-space: nowrap;
    margin-right: 8rem;
    will-change: color;
    transition: color 0.8s ease;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: -75px;
      width: 22px;
      height: 6px;
      background-color: ${(props) => props.theme.textLight};
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 3px;
      will-change: background-color;
      background-color: ${(props) => props.theme.textLight};
      transition: background-color 0.6s ease;
    }

    &:hover {
      color: ${(props) => props.theme.linkHover};

      &:after {
        background-color: ${(props) => props.theme.linkHover};
      }
    }
  }
`

const MintButton = styled.svg`
  cursor: pointer;

  &.selected {
    animation: ${bounce} 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

    rect {
      fill: ${(props) => props.theme.backgroundPurple};
    }

    path {
      fill: ${(props) => props.theme.backgroundPrimary};
    }
  }
`

const MintButtonsLayout = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};

  svg {
    margin: 0.8rem;
  }
`

const ShareButtonsLayout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};

  p {
    padding-right: ${(props) => (props.column ? '0' : '1rem')};
    padding-top: ${(props) => (props.column ? '1rem' : '0')};
  }

  svg {
    margin: 0.8rem;
  }
`

export {
  PageLayout,
  Cursor,
  CursorSmall,
  CursorView,
  Content,
  FeaturedProjects,
  ProjectsSquare,
  TeamSquare,
  Marquee,
  MintButton,
  MintButtonsLayout,
  ShareButtonsLayout,
}
