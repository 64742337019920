import { createGlobalStyle } from 'styled-components'

import gilroyHeavy from '../assets/fonts/Gilroy-Heavy.ttf'
import gilroyHeavyItalic from '../assets/fonts/Gilroy-HeavyItalic.ttf'
import gilroyExtraBold from '../assets/fonts/Gilroy-ExtraBold.ttf'
import gilroyExtraBoldItalic from '../assets/fonts/Gilroy-ExtraBoldItalic.ttf'
import gilroyBold from '../assets/fonts/Gilroy-Bold.ttf'
import gilroyBoldItalic from '../assets/fonts/Gilroy-BoldItalic.ttf'
import gilroySemiBold from '../assets/fonts/Gilroy-SemiBold.ttf'
import gilroySemiBoldItalic from '../assets/fonts/Gilroy-SemiBoldItalic.ttf'
import gilroyMedium from '../assets/fonts/Gilroy-Medium.ttf'
import gilroyMediumItalic from '../assets/fonts/Gilroy-MediumItalic.ttf'
import gilroyRegular from '../assets/fonts/Gilroy-Regular.ttf'
import gilroyRegularItalic from '../assets/fonts/Gilroy-RegularItalic.ttf'
import gilroyLight from '../assets/fonts/Gilroy-Light.ttf'
import gilroyLightItalic from '../assets/fonts/Gilroy-LightItalic.ttf'
import gilroyUltraLight from '../assets/fonts/Gilroy-UltraLight.ttf'
import gilroyUltraLightItalic from '../assets/fonts/Gilroy-UltraLightItalic.ttf'
import gilroyThin from '../assets/fonts/Gilroy-Thin.ttf'
import gilroyThinItalic from '../assets/fonts/Gilroy-ThinItalic.ttf'
import pixerRegular from '../assets/fonts/Pixer-Regular.otf'

const FontFace = createGlobalStyle`
  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyHeavy});
    font-style: normal;
    font-weight: 900;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyHeavyItalic});
    font-style: italic;
    font-weight: 900;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyBold});
    font-style: normal;
    font-weight: 800;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyBoldItalic});
    font-style: italic;
    font-weight: 800;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyExtraBold});
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyExtraBoldItalic});
    font-style: italic;
    font-weight: 700;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroySemiBold});
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroySemiBoldItalic});
    font-style: italic;
    font-weight: 600;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyMedium});
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyMediumItalic});
    font-style: italic;
    font-weight: 500;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyRegular});
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyRegularItalic});
    font-style: italic;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyLight});
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyLightItalic});
    font-style: italic;
    font-weight: 300;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyUltraLight});
    font-style: normal;
    font-weight: 200;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyUltraLightItalic});
    font-style: italic;
    font-weight: 200;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyThin});
    font-style: normal;
    font-weight: 100;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url(${gilroyThinItalic});
    font-style: italic;
    font-weight: 100;
  }

  @font-face {
    font-family: 'Pixer';
    src: url(${pixerRegular});
    font-style: normal;
    font-weight: 400;
  }
`

export default FontFace
