import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const FooterLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.backgroundDark};
  padding: 6rem 9rem;

  @media (max-width: ${breakpoints.screenLG}) {
    padding: 6rem 3rem;
  }

  @media (max-width: ${breakpoints.screenMD}) {
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    padding: 4rem 1.5rem;
  }

  .title {
    padding-bottom: 1rem;
  }

  .links {
    display: flex;

    @media (max-width: ${breakpoints.screenSM}) {
      flex-direction: column;
    }

    .link {
      margin-right: 2rem;

      &:hover {
        &:after {
          transform: scale(1);
        }
      }
    }
  }

  .link {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: ${(props) => props.theme.textLight};
      will-change: transform;
      transform-origin: center;
      transform: scale(0);
      transition: transform 0.4s ease;
    }
  }

  .top-left {
    margin-bottom: 6rem;
    width: 55%;

    @media (max-width: ${breakpoints.screenMD}) {
      margin-bottom: 4rem;
      width: 100%;
    }

    p {
      margin-top: 2rem;
      max-width: 15rem;
    }
  }

  .top-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 6rem;
    width: 45%;

    @media (max-width: ${breakpoints.screenMD}) {
      margin-bottom: 4rem;
      width: 100%;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  .bottom-left {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
    width: 55%;

    @media (max-width: ${breakpoints.screenMD}) {
      order: 1;
      width: 100%;
    }

    a {
      margin-right: 1.5rem;
    }
  }

  .bottom-right {
    width: 45%;

    @media (max-width: ${breakpoints.screenMD}) {
      margin-bottom: 4rem;
      width: 100%;
    }

    p {
      margin-bottom: 1rem;
    }
  }
`

export default FooterLayout
