/**
 * Toggles an 'open' class to the global menu component.
 */
export const toggleMenuClass = () => {
  document.getElementById('menu').classList.toggle('open')
  document.getElementById('menu-icon').classList.toggle('open')
  document.getElementById('header').classList.toggle('fixed')
  document.body.classList.toggle('no-scroll')
}

/**
 * Removes the 'open' class of the global menu component.
 */
export const removeMenuClass = () => {
  document.getElementById('menu').classList.remove('open')
  document.getElementById('menu-icon').classList.remove('open')
  document.getElementById('header').classList.remove('fixed')
  document.body.classList.remove('no-scroll')
}

/**
 * Highlight the list item in view.
 */
export const highlightElementInView = () => {
  const items = document.querySelectorAll('.content .list')

  items.forEach((item) => {
    if ((window.pageYOffset || document.documentElement.scrollTop) + 100 > item.offsetTop) {
      item.classList.add('active')
    } else {
      item.classList.remove('active')
    }
  })
}
