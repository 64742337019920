import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const FormLayout = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.backgroundBlack};
  width: 100%;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 120px;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.08, 0.03, 0.22, 0.87);
    padding: 0 9rem;
    z-index: 1;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 0 3rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      flex-direction: column;
      justify-content: center;
      height: 280px;
      padding: 0;
    }

    &.open {
      height: 200px;

      @media (max-width: ${breakpoints.screenSM}) {
        height: 320px;
      }
    }
  }

  &:hover .scalable {
    transform: scaleY(1.2);
  }

  .scalable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background-color: ${(props) => props.theme.backgroundBlack};
    will-change: height, transform;
    transition: transform 0.4s cubic-bezier(0.08, 0.03, 0.22, 0.87),
      height 0.6s cubic-bezier(0.08, 0.03, 0.22, 0.87);

    @media (max-width: ${breakpoints.screenSM}) {
      height: 280px;
    }

    &.open {
      height: 200px;
      transform: scaleY(1.3);

      @media (max-width: ${breakpoints.screenSM}) {
        height: 320px;
      }
    }
  }

  .text-center {
    display: flex;
    align-items: center;

    @media (max-width: ${breakpoints.screenSM}) {
      flex-direction: column;
      text-align: center;
      padding-bottom: 1.5rem;
      max-width: 15rem;
    }

    span {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: ${(props) => props.theme.backgroundPrimary};
      }
    }

    svg {
      margin-left: 2rem;
      transform: rotate(0deg);

      @media (max-width: ${breakpoints.screenMD}) {
        margin-left: 0.5rem;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        margin-top: 1rem;
      }
    }
  }

  .arrow {
    transform: rotate(0);
    will-change: transform;

    &:first-child {
      &.open {
        transform: rotate(180deg);
      }

      @media (max-width: ${breakpoints.screenLG}) {
        display: none;
      }
    }

    &:last-child {
      &.open {
        transform: rotate(-180deg);
      }
    }
  }

  .body {
    display: flex;
    justify-content: center;
    height: 0;
    will-change: height;
    transition: height 0.5s ease;
    overflow-y: hidden;

    &.open {
      height: 1280px;

      form {
        opacity: 1;
      }
    }
  }

  form {
    padding: 4rem 9rem;
    text-align: center;
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s ease;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 4rem 3rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      flex-direction: column;
      padding: 4rem 1.5rem;
    }
  }

  .recaptcha {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .note {
    font-size: 0.75rem;
    margin-top: 1rem;
  }

  button {
    margin-top: 3rem;

    &:disabled:before {
      display: block;
    }
  }
`

const FormControl = styled.label`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 4rem;
  width: 50vw;

  @media (max-width: ${breakpoints.screenLG}) {
    width: 100%;
  }

  &:hover span,
  &:hover input {
    opacity: 1;
  }

  span {
    font-size: 1.6rem;
    line-height: 1.5;
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textLight};
    font-weight: 700;
    padding-bottom: 1rem;
    opacity: 0.5;
    transition: opacity 0.5s ease;

    @media (max-width: ${breakpoints.screenSM}) {
      font-size: 1.25rem;
    }
  }

  input {
    background: none;
    border: 0;
    padding: 0.8rem;
    font-size: 1.25rem;
    font-family: ${({ theme }) => theme.fontPrimary};
    color: ${(props) => props.theme.textLight};
    text-align: center;
    border-bottom: 1px solid ${(props) => props.theme.backgroundBlue};
    width: 100%;
    opacity: 0.5;
    transition: opacity 0.5s ease;

    &:focus,
    &:focus + label {
      opacity: 1;
    }

    &::placeholder {
      font-size: 1.25rem;
      color: ${(props) => props.theme.textLight};
      text-align: center;
    }
  }
`

export { FormLayout, FormControl }
