const colors = {
  black: '#2e2e2e',
  dark: '#202020',
  white: '#fff',
  green: '#17e6a4',
  blue: '#1747C8',
  purple: '#591ed8',
  red: '#f55e4a',
  yellow: '#eaaa1b',
  transparent: 'transparent',
}

const theme = {
  // font
  fontPrimary: 'Gilroy',
  fontSecondary: 'Pixer',
  // background
  backgroundPrimary: colors.white,
  backgroundGreen: colors.green,
  backgroundBlue: colors.blue,
  backgroundPurple: colors.purple,
  backgroundRed: colors.red,
  backgroundYellow: colors.yellow,
  backgroundBlack: colors.black,
  backgroundDark: colors.dark,
  // text
  textPrimary: colors.black,
  textLight: colors.white,
  textHover: colors.red,
  linkHover: colors.purple,
  // button
  buttonPrimary: colors.red,
  buttonSecondary: colors.white,
  buttonGhost: colors.transparent,
  // sections
  form: colors.yellow,
  header: colors.transparent,
  footer: colors.black,
}

export { theme, colors }
