import React from 'react'

import { colors } from '../../constants/theme'

const Logo = ({ background = colors.transparent, border = colors.white, text = colors.white }) => (
  <svg
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="logo"
  >
    <path d="M96 4H4V96H96V4Z" fill={background} />
    <path
      d="M31.8626 68.1812V72.7262H36.4139V90.9102H40.9673V72.7262V68.1812H31.8626Z"
      fill={text}
    />
    <path d="M31.8625 72.7263H27.3112V81.8161H31.8625V72.7263Z" fill={text} />
    <path d="M27.3112 81.8159H22.7603V90.9081H27.3112V81.8159Z" fill={text} />
    <path
      d="M22.7603 81.816V72.7262H18.2089V68.1812H9.10672V72.7262V90.9102H13.6576V72.7262H18.2089V81.816H22.7603Z"
      fill={text}
    />
    <path d="M50.0694 59.0906H45.516V63.6357H50.0694V59.0906Z" fill={text} />
    <path d="M50.0694 68.1812H45.516V90.9082H50.0694V68.1812Z" fill={text} />
    <path
      d="M68.2767 68.1812V72.7262H59.1745V90.9082H54.6211V68.1812H68.2767ZM68.2767 72.7262H72.828V90.9082H68.2767V72.7262Z"
      fill={text}
    />
    <path
      d="M81.9303 86.3632H77.379V63.6361H81.9303V68.1812H91.0329V72.7263H81.9303V86.3632ZM81.9303 86.3632H91.0329V90.9103H81.9303V86.3632Z"
      fill={text}
    />
    <path
      d="M0 100H100.139V0H0V100ZM95.5857 95.4549H4.55336V4.54713H95.5857V95.4549Z"
      fill={border}
    />
  </svg>
)

export default Logo
