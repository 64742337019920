import React from 'react'

import { colors } from '../../constants/theme'

const Arrow = ({ background = colors.white, className }) => (
  <svg
    width="26"
    height="13"
    viewBox="0 0 26 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M0 0L13 13L26 0H0Z" fill={background} />
  </svg>
)

export default Arrow
