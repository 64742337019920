import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import { theme } from '../constants/theme'

import GlobalStyles from '../styles/GlobalStyles'
import FontFace from '../styles/FontFace'
import { PageLayout } from '../styles/PageLayout'

import Cursor from './Cursor'
import Menu from './Menu'
import Footer from './Footer'

/**
 * A page layout including all global styles and theme provider.
 * Used as a wrapper in gatsby-browser and gatsby-ssr.
 * @param {ComponentProps} props
 * @param {ChildNode} props.children
 * @param {Object} props.location
 * @returns {StatelessComponent}
 */
const Page = ({ children, location }) => (
  <>
    <GlobalStyles />
    <FontFace />
    <ThemeProvider theme={theme}>
      <Cursor location={location} />
      <Menu />
      <PageLayout>{children}</PageLayout>
      <Footer />
    </ThemeProvider>
  </>
)

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

export default Page
