import React from 'react'

import { colors } from '../../constants/theme'

const Facebook = ({ background = colors.white }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8586 24V13.0533H17.5315L18.0825 8.78588H13.8586V6.06176C13.8586 4.82664 14.2002 3.98492 15.9734 3.98492L18.2312 3.98399V0.167076C17.8407 0.116334 16.5004 0 14.9404 0C11.6829 0 9.45275 1.98836 9.45275 5.63912V8.78588H5.76872V13.0533H9.45275V24H13.8586Z"
      fill={background}
    />
  </svg>
)

export default Facebook
