// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-atrium-interieri-js": () => import("./../../../src/pages/project/atrium-interieri.js" /* webpackChunkName: "component---src-pages-project-atrium-interieri-js" */),
  "component---src-pages-project-butik-festival-js": () => import("./../../../src/pages/project/butik-festival.js" /* webpackChunkName: "component---src-pages-project-butik-festival-js" */),
  "component---src-pages-project-coronini-caffee-js": () => import("./../../../src/pages/project/coronini-caffee.js" /* webpackChunkName: "component---src-pages-project-coronini-caffee-js" */),
  "component---src-pages-project-example-project-js": () => import("./../../../src/pages/project/example-project.js" /* webpackChunkName: "component---src-pages-project-example-project-js" */),
  "component---src-pages-project-gengigel-js": () => import("./../../../src/pages/project/gengigel.js" /* webpackChunkName: "component---src-pages-project-gengigel-js" */),
  "component---src-pages-project-ip-posocje-js": () => import("./../../../src/pages/project/ip-posocje.js" /* webpackChunkName: "component---src-pages-project-ip-posocje-js" */),
  "component---src-pages-project-mlada-pobuda-js": () => import("./../../../src/pages/project/mlada-pobuda.js" /* webpackChunkName: "component---src-pages-project-mlada-pobuda-js" */),
  "component---src-pages-project-nord-hard-seltzer-js": () => import("./../../../src/pages/project/nord-hard-seltzer.js" /* webpackChunkName: "component---src-pages-project-nord-hard-seltzer-js" */),
  "component---src-pages-project-obcina-tolmin-js": () => import("./../../../src/pages/project/obcina-tolmin.js" /* webpackChunkName: "component---src-pages-project-obcina-tolmin-js" */),
  "component---src-pages-project-slovenia-green-js": () => import("./../../../src/pages/project/slovenia-green.js" /* webpackChunkName: "component---src-pages-project-slovenia-green-js" */),
  "component---src-pages-project-to-poletje-bo-nord-js": () => import("./../../../src/pages/project/to-poletje-bo-nord.js" /* webpackChunkName: "component---src-pages-project-to-poletje-bo-nord-js" */),
  "component---src-pages-project-tolmin-2030-js": () => import("./../../../src/pages/project/tolmin-2030.js" /* webpackChunkName: "component---src-pages-project-tolmin-2030-js" */),
  "component---src-pages-project-visit-good-place-js": () => import("./../../../src/pages/project/visit-good-place.js" /* webpackChunkName: "component---src-pages-project-visit-good-place-js" */),
  "component---src-pages-project-vitasis-js": () => import("./../../../src/pages/project/vitasis.js" /* webpackChunkName: "component---src-pages-project-vitasis-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

