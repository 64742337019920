import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const Button = styled.button`
  position: relative;
  font-size: 1.25rem;
  line-height: 1;
  font-family: ${({ theme }) => theme.fontPrimary};
  font-weight: 700;
  padding: 1.2rem 1.8rem;
  border: 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: default;
    pointer-events: none;

    &:before {
      display: none;

      @media (max-width: ${breakpoints.screenMD}) {
        display: block;
      }
    }
  }
`

const ButtonPrimary = styled(Button)`
  background-color: ${({ theme }) => theme.buttonGhost};
  color: ${({ theme }) => theme.textLight};

  span {
    position: relative;
    z-index: 1;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 60px;
    height: 100%;
    will-change: width;
    background-color: ${({ theme }) => theme.buttonPrimary};
    transition: width 0.4s cubic-bezier(0.08, 0.03, 0.22, 0.87);

    @media (max-width: ${breakpoints.screenMD}) {
      width: 100%;
    }
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
`

const ButtonSecondary = styled(Button)`
  background-color: ${({ theme }) => theme.buttonGhost};
  color: ${({ theme }) => theme.backgroundBlue};

  span {
    position: relative;
    z-index: 1;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 60px;
    height: 100%;
    will-change: width;
    background-color: ${({ theme }) => theme.buttonSecondary};
    transition: width 0.4s cubic-bezier(0.08, 0.03, 0.22, 0.87);

    @media (max-width: ${breakpoints.screenMD}) {
      width: 100%;
    }
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
`

const ButtonGhost = styled.button`
  background-color: ${({ theme }) => theme.buttonGhost};
  border: 0;
  padding: 0;
  cursor: pointer;
`

export { ButtonPrimary, ButtonSecondary, ButtonGhost }
