import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { colors } from '../constants/theme'

import { FormLayout, FormControl } from '../styles/FormLayout'
import { ButtonSecondary } from '../styles/ButtonLayout'
import { SubtitleM, ParagraphM, ParagraphS } from '../styles/Typography'

import Arrow from './icons/arrow'
import Surfs from './icons/surfs'

/**
 * The contact form, to be included in the footer.
 * @returns {FunctionComponent}
 */
const Form = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [project, setProject] = useState('')
  const [budget, setBudget] = useState('')
  const [captcha, setCaptcha] = useState('')

  const isInvalid = name === '' || email === '' || project === '' || captcha === ''

  return (
    <FormLayout className="form">
      <div className={`head${isOpen ? ' open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
        <Arrow className={`arrow${isOpen ? ' open' : ''}`} />
        <div className="text-center">
          <SubtitleM color={colors.white}>
            Ta obrazec ne pozna neumnih vprašanj. <span>Piši nam.</span>
          </SubtitleM>
          <Surfs />
        </div>
        <Arrow className={`arrow${isOpen ? ' open' : ''}`} />
      </div>
      <div className={`scalable${isOpen ? ' open' : ''}`}></div>
      <div className={`body${isOpen ? ' open' : ''}`}>
        <form method="post" action="https://getform.io/f/6114a626-2d8a-460b-89ca-72a8991a7a25">
          <FormControl>
            <input
              type="text"
              name="name"
              value={name}
              placeholder="Ali kako ti pravijo prijatelji"
              onChange={({ target }) => setName(target.value)}
            />
            <span>1. Najprej nam povej, kako ti je ime *</span>
          </FormControl>
          <FormControl>
            <input
              type="email"
              name="email"
              value={email}
              placeholder="Na ta naslov bomo odgovorili"
              onChange={({ target }) => setEmail(target.value)}
            />
            <span>2. Tvoj email *</span>
          </FormControl>
          <FormControl>
            <input
              type="text"
              name="company"
              value={company}
              placeholder="Da vemo s kom imamo opravka"
              onChange={({ target }) => setCompany(target.value)}
            />
            <span>3. Mogoče tudi ime podjetja</span>
          </FormControl>
          <FormControl>
            <input
              type="text"
              name="project"
              value={project}
              placeholder="To je vedno dobro vedeti"
              onChange={({ target }) => setProject(target.value)}
            />
            <span>4. Za kakšen projekt gre *</span>
          </FormControl>
          <FormControl>
            <input
              type="text"
              name="budget"
              value={budget}
              placeholder="Vsaj približno"
              onChange={({ target }) => setBudget(target.value)}
            />
            <span>5. Kakšen budget imaš v mislih</span>
          </FormControl>
          <ParagraphM color={colors.white} weight="300">
            Mogoče bo to le eno sporočilo, mogoče pa prvi korak do hude zgodbe.
          </ParagraphM>
          <ParagraphM color={colors.white} weight="300">
            Let's find out!
          </ParagraphM>
          <div className="recaptcha">
            <ReCAPTCHA
              sitekey="6Lc_y8khAAAAAN3d1E4iEmNImS7H0WS16Asn8zqL"
              theme="dark"
              onChange={(value) => setCaptcha(value)}
            />
          </div>
          <ParagraphS color={colors.white} weight="300" className="note">
            * obvezna polja
          </ParagraphS>
          <ButtonSecondary type="submit" disabled={isInvalid}>
            <span>Pošlji sporočilo</span>
          </ButtonSecondary>
        </form>
      </div>
    </FormLayout>
  )
}

export default Form
