import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    word-wrap: break-word;
  }

  html {
    min-height: 100%;
  }

  html,
  body {
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    font-family: 'Ubuntu', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(222, 222, 222, .75);
  }
  
  ::-webkit-scrollbar-thumb {
    width: 7px;
    border-radius: 4px;
    background: rgba(0, 0, 0, .2);
    transition: all 0.5s ease-out;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, .4);
  }

  .no-scroll {
    overflow: hidden !important;
  }

  .hide {
    display: none !important;
  }

  ul,
  menu,
  dir {
    padding-left: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  figure {
    margin: 0;
  }

  *:focus,
  *:active {
    outline: 0 !important;
  }

  ::-moz-selection { 
    color: #fff;
    background: #7f7f7f;
  }

  ::selection {
    color: #fff;
    background: #7f7f7f;
  }

  img {
    max-width: 100%;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
  }
`

export default GlobalStyles
