import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import useMousePosition from '../hooks/useMousePosition'

import { Cursor, CursorSmall, CursorView } from '../styles/PageLayout'
import { ParagraphS } from '../styles/Typography'

/**
 * A custom cursor with three states:
 * default circle, hovering, and project indicating.
 * @param {ComponentProps} props
 * @param {Object} props.location
 * @returns {FunctionComponent}
 */
const CursorPosition = ({ location }) => {
  const { x, y } = useMousePosition()

  const [isHovering, setIsHovering] = useState(false)
  const [isOverProject, setIsOverProject] = useState(false)

  useEffect(() => {
    setIsHovering(false)
    setIsOverProject(false)
  }, [location.pathname])

  useEffect(() => {
    const links = document.querySelectorAll('a')
    const buttons = document.querySelectorAll('button')
    const form = document.querySelector('.form')
    const clickables = [...links, ...buttons, form]
    clickables.forEach((clickable) => {
      clickable.addEventListener('mouseenter', () => setIsHovering(true))
      clickable.addEventListener('mouseleave', () => setIsHovering(false))
    })

    const projects = document.querySelectorAll('.project-image')
    projects.forEach((project) => {
      project.addEventListener('mouseenter', () => setIsOverProject(true))
      project.addEventListener('mouseleave', () => setIsOverProject(false))
    })
  }, [isHovering, isOverProject])

  return (
    <>
      <Cursor
        style={{
          transform: `translate(${x}px, ${y}px)`,
          opacity: isHovering ? 0 : 1,
        }}
      ></Cursor>

      <CursorSmall
        style={{
          transform: `translate(${x}px, ${y}px)`,
          opacity: isOverProject ? 0 : 1,
        }}
      ></CursorSmall>

      <CursorView
        style={{
          transform: `translate(${x}px, ${y}px)`,
          opacity: isOverProject ? 1 : 0,
        }}
      >
        <ParagraphS>Poglej</ParagraphS>
        <ParagraphS>Projekt</ParagraphS>
      </CursorView>
    </>
  )
}

CursorPosition.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}
export default CursorPosition
